import React, { useEffect, useState } from "react";
import "../DashBoard/Dashboard.css";
import axios from "../Schemas/API";
import Eye from "../Images/eye.png";
import Delete from "../Images/delete.png";
import { useNavigate } from "react-router";
import { Modal } from "react-bootstrap";
const Dashboard = () => {
  const [getBoard, setGetBoard] = useState([]);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [popu, setPopu] = useState([]);

  // DashBoard API Function...........................................................
  const MyToken = localStorage.getItem("TOKEN");
  const navigate = useNavigate();
  const getDashBoard = async () => {
    try {
      const res = await axios.post(
        "/dashboard/get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      setGetBoard(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDashBoard();
  }, []);
  const handleShow = (item) => {
    setPopu(item);
    setShow(true);
  };
  const handleData = (items) => {
    const item = { data: items, page: "dashboard" };
    navigate("/PostView", {
      state: {
        item,
      },
    });
  };
  // Delete API function here..................................................................
  const PostDelete = async () => {
    try {
      const res = await axios.post(
        "/post/delete",
        {
          id: popu?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      setShow(false);
      getDashBoard();
      console.log(res, "delete ho gya");
    } catch (error) {
      console.log(error);
    }
  };
  const Deletepopup = () => {
    return (
      <Modal show={show} onHide={handleClose} backdrop="static" size="md">
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <span className="span-delete-heres">View Post Detail</span>
        <hr />
        <Modal.Body>
          <div className="table-responsive-add">
            <div className="flex-delete-popup-refunds">
              <img src={popu?.gallery} alt="" className="Two-img-popu-heres" />
              <div className="name-post-span" style={{ marginTop: "0.8rem" }}>
                <span>{popu?.created_by?.firstname}</span>
                <span>{popu?.created_by?.lastname}</span>
              </div>
              <hr className="def-border-post" />
              <span className="span-delete-sure-you">
                Are you sure you want to delete this post?
              </span>
              <div className="post-want-flex-yes">
                <button
                  style={{ background: "red", color: "#fff" }}
                  onClick={() => setShow(false)}
                >
                  No
                </button>
                <button
                  style={{
                    background: "#fff",
                    color: "red",
                    border: "1px solid red",
                  }}
                  onClick={() => PostDelete(item?.id)}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <>
      <Deletepopup show={show} handleClose={() => setShow(false)} />

      <div className="Main-Content-class">
        <div className="four-card-products">
          <div className="box-border-products">
            <span className="title-products">Total Users</span>
            <span className="number-def">{getBoard.TotalUser}</span>
            <hr />
            <div style={{ display: "flex", gap: "0.8rem", cursor: "pointer" }}>
              <a className="blue-inbox-data">
                <span className="number-def" style={{ color: "#008cff" }}>
                  {getBoard.EmailUser}
                </span>
                <span>EmailUser</span>
              </a>
              <a className="two-inbox-blue-data">
                <span className="number-def" style={{ color: "#ffff" }}>
                  {getBoard.SocialUser}
                </span>
                <span>SocialUser</span>
              </a>
            </div>
          </div>

          <div
            className="box-border-products"
            style={{ borderBottom: "3px solid #ffca2c" }}
          >
            <span className="title-products" style={{ color: "#ffc730" }}>
              Total Post
            </span>
            <span className="number-def">{getBoard.AllPost}</span>
            <hr />
            <div style={{ display: "flex", gap: "0.8rem", cursor: "pointer" }}>
              <a
                className="blue-inbox-data"
                style={{
                  background: "#ffecd1",
                  color: "#ff9b10",
                  fontWeight: "500",
                }}
              >
                <span>{getBoard.ActivePost}</span>
                <span>Active</span>
              </a>
              <a
                className="two-inbox-blue-data"
                style={{
                  background: "#ffca2c",
                  color: "#000",
                  fontWeight: "500",
                }}
              >
                <span>{getBoard.DeactivePost}</span>
                <span>Deactive</span>
              </a>
            </div>
          </div>
        </div>
        <div className="table-responsive-add" style={{ marginTop: "3rem" }}>
          <div className="easy-add-scroll-new">
            <div className="all-btn-texts">
              <span className="span-all-btn-texts">Recently Post</span>
              <button onClick={() => navigate("/Post")}>View All</button>
            </div>
            <table style={{ marginTop: "3rem" }}>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Like</th>
                  <th>Comment</th>
                  <th>Repost</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getBoard?.RecentPost?.map((item, index) => {
                  const serialNumber = index + 1;
                  return (
                    <tr key={item.id}>
                      <td className="">{serialNumber}</td>
                      <td>
                        <img
                          src={item.gallery}
                          alt=""
                          className="img-popu-heres"
                        />
                      </td>
                      <td>
                        <div>
                          <div
                            className="add-bold-in-font"
                            style={{
                              display: "flex",
                              gap: "0.5rem",
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="responsive-design"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {item.created_by.firstname}
                            </div>
                            <div
                              className="responsive-design"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {item.created_by.lastname}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="">{item.desc}</td>
                      <td className="">{item.total_like}</td>
                      <td className="">{item.total_comment}</td>
                      <td className="">{item.total_repost}</td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            className="delete-buttons-red"
                            style={{ border: "1px solid #15ca20" }}
                            onClick={() =>
                              handleData({
                                userId: item.created_by.id,
                                Postid: item.id,
                              })
                            }
                          >
                            <img
                              src={Eye}
                              alt="Delete"
                              className="delete-btn-red"
                            />
                          </button>
                          <button
                            className="delete-buttons-red"
                            onClick={() => {
                              handleShow(item);
                            }}
                          >
                            <img
                              src={Delete}
                              alt="Delete"
                              className="delete-btn-red"
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th>Sr No</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Like</th>
                  <th>Comment</th>
                  <th>Repost</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
